import { Box, Paper, Typography } from '@mui/material';
import useCatalog from './useCatalog';
import CatalogTable from './CatalogTable';
import CatalogSkeleton from './CatalogSkeleton';
import { useAppSelector } from 'hooks/redux-hooks';
import MainLayout from 'layouts/MainLayout';
import { selectCatalogStatus } from 'slices/catalogSlice';
import Tabs from 'components/Tabs';

const Catalog = () => {
  const status = useAppSelector(selectCatalogStatus);
  const {
    filterOpen,
    setFilterOpen,
    columnFiltersWithCounts,
    rows,
    setFilters,
    filteredData,
    table,
    activeTab,
    setActiveTab,
    isForwardCurves,
    filters,
    showDiscontinued,
    setShowDiscontinued,
    expandedData,
    setExpandedData,
    columnVisibility,
    specialFilters,
    setSpecialFilters
  } = useCatalog();

  const tabLabel = (name: string, rectBgColor: string) => (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box
        sx={{
          width: 6,
          height: 6,
          backgroundColor: rectBgColor,
          mr: 0.5
        }}
      />
      {name}
    </Box>
  );

  const catalogTabs = [
    {
      name: 'Spot',
      label: tabLabel('Spot', 'table.spot')
    },
    {
      name: 'Forward curves',
      label: tabLabel('Forward Curves', 'table.forwardCurve')
    }
  ];

  return (
    <MainLayout>
      {status !== 'loading' && status !== 'idle' ? (
        <>
          <Box sx={{ display: 'flex', width: '100%', mt: 3 }}>
            <Box sx={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'flex-end', width: 390 }}>
              <Typography
                variant='h2'
                sx={{ mr: 3 }}
                noWrap
              >
                Data Catalog
              </Typography>
              <Typography
                noWrap
                variant='h6'
              >
                GX Indexes [{filteredData?.length.toLocaleString('en-US')}]
              </Typography>
            </Box>
            <Tabs
              tabs={catalogTabs}
              minTabWidth={300}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          </Box>
          <Paper sx={{ mt: 3 }}>
            <CatalogTable
              filterOpen={filterOpen}
              setFilterOpen={setFilterOpen}
              columnFiltersWithCounts={columnFiltersWithCounts}
              rows={rows}
              filters={filters}
              setFilters={setFilters}
              filteredData={filteredData}
              table={table}
              isForwardCurves={isForwardCurves}
              expandedData={expandedData}
              setExpandedData={setExpandedData}
              showDiscontinued={showDiscontinued}
              setShowDiscontinued={setShowDiscontinued}
              columnVisibility={columnVisibility}
              specialFilters={specialFilters}
              setSpecialFilters={setSpecialFilters}
            />
          </Paper>
        </>
      ) : (
        <CatalogSkeleton />
      )}
    </MainLayout>
  );
};

export default Catalog;
