import { Box, Button, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import SmallDialog from 'components/SmallDialog';
import { DisableableButton } from 'components/DisableableButton';
import { ApiKeyOutput } from 'slices/apiKeyListSlice';
const ConfirmDialog = ({
  open,
  setOpen,
  deleteCallback
}: {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  deleteCallback: () => void;
}) => {
  return (
    <SmallDialog
      open={open}
      handleClose={() => setOpen(false)}
    >
      <DialogTitle sx={{ width: 590, p: 3 }}>Delete API key?</DialogTitle>
      <>
        <DialogContent sx={{ paddingBottom: 2 }}>
          <Box sx={{ paddingTop: 2, display: 'flex', alignItems: 'flex-end' }}>
            <Typography
              variant='subtitle2'
              sx={{ mr: 3 }}
            >
              Are you sure you want to delete this API key? This action cannot be undone, and any
              applications using this key will lose access.
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions sx={{ p: 3 }}>
          <Button
            sx={{ mr: 3, pr: 0, minWidth: '40px' }}
            variant='text'
            onClick={() => setOpen(false)}
          >
            Cancel
          </Button>
          <DisableableButton
            clickCallback={() => deleteCallback()}
            variant='contained'
          >
            Delete Key
          </DisableableButton>
        </DialogActions>
      </>
    </SmallDialog>
  );
};
export default ConfirmDialog;
