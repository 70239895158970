import { Navigate, Outlet } from 'react-router-dom';
import { getGxTokenFromStorage } from 'utils/commonFunctions';

const ProtectedLayout = () => {
  const gxToken = getGxTokenFromStorage();

  if (!gxToken) {
    return (
      <Navigate
        replace
        to='/login'
      />
    );
  }

  return <Outlet />;
};

export default ProtectedLayout;
