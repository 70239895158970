import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { useEffect, useRef, useState } from 'react';
import LegendDropdown from './LegendDropdown';
const StockChart = ({ options }: { options: any }) => {
  const chartComponentRef = useRef(null);
  const [chartObject, setChartObject] = useState(null);

  useEffect(() => {
    if (chartComponentRef.current) {
      const charContainer: any = chartComponentRef.current;
      setChartObject(charContainer.chart);
    }
  }, [chartComponentRef.current]);

  return (
    <div className='stock-chart'>
      <HighchartsReact
        highcharts={Highcharts}
        constructorType={'stockChart'}
        options={options}
        ref={chartComponentRef}
      />
      {chartObject && <LegendDropdown chart={chartObject} />}
    </div>
  );
};
export default StockChart;
