import { useNavigate } from 'react-router-dom';
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { LogoutSharp, SettingsInputComponentOutlined } from '@mui/icons-material';
import { useAppDispatch } from 'hooks/redux-hooks';
import { logout } from 'slices/authSlice';

const ProfileDropdown = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const profileItems = [
    {
      name: 'Settings',
      onClick: () => navigate('/profile'),
      icon: <SettingsInputComponentOutlined fontSize='small' />
    },
    {
      name: 'Logout',
      onClick: () => {
        dispatch(logout());
        navigate('/login');
      },
      icon: <LogoutSharp fontSize='small' />
    }
  ];

  return (
    <List
      sx={{
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      {profileItems.map(({ name, icon, onClick }) => (
        <ListItem
          key={name}
          disablePadding
          sx={{ display: 'block', '&:last-child': { mt: 'auto' } }}
        >
          <ListItemButton
            sx={{
              minHeight: 48,
              px: 3,
              justifyContent: 'center'
            }}
            onClick={onClick}
          >
            <ListItemIcon
              sx={{
                fontSize: '14px',
                minWidth: 0,
                justifyContent: 'center',
                mr: 3,
                ml: 0.5
              }}
            >
              {icon}
            </ListItemIcon>
            <ListItemText primary={name} />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
};

export default ProfileDropdown;
