import { useCallback } from 'react';
import { useSnackbar, SnackbarKey } from 'notistack';
import { CircularProgress, Theme } from '@mui/material';
import { Cancel, CheckCircle, InfoRounded } from '@mui/icons-material';

declare module 'notistack' {
  interface VariantOverrides {
    warning: false;
    loading: true;
    default: false;
  }
}

const useResponseMessage = () => {
  const { closeSnackbar } = useSnackbar();

  const handleDismiss = useCallback(
    (id: SnackbarKey) => {
      closeSnackbar(id);
    },
    [closeSnackbar]
  );

  const messages = {
    loading: 'Loading your data, please wait.',
    info: 'Nothing to display here. Try changing your filters or adding new data.',
    error: 'Something went wrong. Please try again later.',
    success: 'Changes saved!'
  };

  const icons = {
    loading: <CircularProgress size={'20px'} />,
    info: <InfoRounded sx={theme => ({ fontSize: 16, color: theme.palette.info.light })} />,
    error: <Cancel sx={theme => ({ fontSize: 16, color: theme.palette.error.main })} />,
    success: <CheckCircle sx={theme => ({ fontSize: 16, color: theme.palette.success.main })} />
  };

  const borderColours = (theme: Theme) => {
    return {
      loading: theme.palette.primary.main,
      info: theme.palette.info.light,
      error: theme.palette.error.main,
      success: theme.palette.success.main
    };
  };

  return {
    handleDismiss,
    messages,
    icons,
    borderColours
  };
};

export default useResponseMessage;
