import { Row, flexRender } from '@tanstack/react-table';
import { Link, useTheme } from '@mui/material';
import { LockOutlined } from '@mui/icons-material';
import { getDiffColor } from 'utils/commonFunctions';
import DateText from 'components/DateText';
import { DateTextVariants } from 'components/DateText/useDateText';
import Tooltip from 'components/Tooltip';

const CatalogTableRow = ({
  highlightedRowIndex,
  setHighlightedRowIndex,
  virtualRow,
  row,
  isForwardCurves,
  columnVisibility // though it is not used in the component, needed for re-rendering
}: {
  highlightedRowIndex: number | null;
  setHighlightedRowIndex: (index: number | null) => void;
  virtualRow: any;
  row: Row<any>;
  isForwardCurves: boolean;
  columnVisibility: boolean;
}) => {
  const {
    original: { Last, Change }
  } = row;

  const { palette } = useTheme();

  const isRestrictedData = !Last && !Change;
  const isHighlighted = virtualRow.index === highlightedRowIndex;

  const returnRowBgColor = () => {
    if (isHighlighted && isForwardCurves) return palette.table.forwardCurve;
    if (isHighlighted) return palette.table.spot;
    if (isRestrictedData && !isForwardCurves) return palette.table.infoRowBg;
    if (virtualRow.index % 2 === 0) return palette.secondary.main;
    if (virtualRow.index % 2 !== 0) return palette.background.paper;
  };

  const returnCellColor = (isChangeColumn?: boolean, value?: string) => {
    if (isRestrictedData && !isForwardCurves) return palette.table.infoRowText;
    if (isChangeColumn) return getDiffColor(Number(value));
    return 'inherit';
  };

  const handleRowClick = () => {
    setHighlightedRowIndex(isHighlighted ? null : virtualRow.index);
  };

  return (
    // eslint-disable-next-line
    <div
      data-index={virtualRow.index}
      key={row.id}
      style={{
        display: 'flex',
        position: 'absolute',
        transform: `translateY(${virtualRow.start}px)`,
        width: '100%',
        height: '23px',
        padding: 0,
        paddingLeft: '8px',
        backgroundColor: returnRowBgColor()
      }}
      onClick={handleRowClick}
    >
      {row.getVisibleCells().map(cell => {
        const columnId = cell.column.id;
        const cellValue = cell.getValue() as string;

        const style = {
          display: 'flex',
          alignItems: 'center',
          width: cell.column.getSize(),
          padding: '1px 8px',
          fontSize: '14px',
          justifyContent: ['Last', 'Change', 'Periods'].includes(columnId)
            ? 'flex-end'
            : 'flex-start',
          color: returnCellColor(columnId === 'Change', cellValue)
        };

        const renderCellContent = () => {
          if (columnId === 'Title' || columnId === 'IndexModule') {
            return (
              <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {cellValue}
              </div>
            );
          }

          if (columnId === 'Index') {
            return cellValue === 'Y' ? 'Index' : 'High, Mid, Low';
          }

          if (columnId === 'Factsheet') {
            return (
              <Link
                href={`https://factsheets.g-x.co/${row.original['Code']}.pdf`}
                target='_blank'
                rel='noopener noreferrer'
                color='text.primary'
                sx={{
                  textDecoration: 'none',
                  fontSize: 15,
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%'
                }}
              >
                ❏
              </Link>
            );
          }

          if (columnId === 'Last' || columnId === 'Change') {
            return <>{(+cellValue).toLocaleString('en-US')}</>;
          }

          if (isRestrictedData && (columnId === 'Last' || columnId === 'Change')) {
            return (
              <Tooltip
                title={
                  <>
                    You do not have access to this data.{' '}
                    <Link
                      href='https://www.general-index.com/contact-us'
                      target='_blank'
                      rel='noopener noreferrer'
                      color='text.primary'
                      sx={{ fontWeight: 700 }}
                    >
                      Request access
                    </Link>
                  </>
                }
              >
                <LockOutlined
                  sx={{ fontSize: '16px' }}
                  color='inherit'
                />
              </Tooltip>
            );
          }

          if (columnId === 'LastDate' || columnId === 'StartDate' || columnId === 'EndDate') {
            return (
              <DateText
                dateString={cellValue}
                variant={DateTextVariants.FULL}
              />
            );
          }

          return flexRender(cell.column.columnDef.cell, cell.getContext());
        };

        return (
          <div
            key={cell.id}
            style={style}
          >
            {renderCellContent()}
          </div>
        );
      })}
    </div>
  );
};

export default CatalogTableRow;
