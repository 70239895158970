import { ReactNode, useEffect, useState } from 'react';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import { Box, Collapse, Container, IconButton } from '@mui/material';
import { ChevronLeft } from '@mui/icons-material';
import { SnackbarProvider } from 'notistack';
import SearchAndStreamBar from 'components/SearchAndStreamBar';
import NavBar from 'components/NavBar';
import GoLogo from 'components/GoLogo';
import { getInsightsList } from 'slices/insightsListSlice';
import { useAppDispatch } from 'hooks/redux-hooks';
import { getUserConfig } from 'slices/userConfigSlice';
import ResponseMessage from 'components/ResponseMessage';

const drawerWidth = 250;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(9)} + 1px)`
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== 'open'
})(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  variants: [
    {
      props: ({ open }) => open,
      style: {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme)
      }
    },
    {
      props: ({ open }) => !open,
      style: {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme)
      }
    }
  ]
}));

const MainLayout = ({ children }: { children: ReactNode }) => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    dispatch(getInsightsList());
    dispatch(getUserConfig());
  }, []);

  const handleToggleDrawer = () => {
    setOpen(!open);
  };

  const handleDrawerOpen = () => {
    if (!open) setOpen(true);
  };

  return (
    <Container
      sx={theme => ({
        display: 'flex',
        height: '100%',
        width: '100%',
        pr: '0!important',
        pl: '0!important',
        [theme.breakpoints.up('xl')]: {
          borderLeft: '1px solid #121836'
        }
      })}
      maxWidth='xl'
      className='visible-scrollbar'
    >
      <Drawer
        variant='permanent'
        open={open}
        onClick={handleDrawerOpen}
        sx={{
          '& .MuiDrawer-paper': {
            backgroundColor: 'background.default'
          }
        }}
      >
        <DrawerHeader sx={{ my: 3, px: 2, justifyContent: open ? 'initial' : 'center' }}>
          <GoLogo
            open={open}
            size={40}
            onClick={handleToggleDrawer}
            beta
          />
          <Collapse
            orientation='horizontal'
            timeout={500}
            in={open}
            unmountOnExit
          >
            <IconButton onClick={handleToggleDrawer}>
              <ChevronLeft />
            </IconButton>
          </Collapse>
        </DrawerHeader>
        <NavBar open={open} />
      </Drawer>
      <Box
        sx={{
          flexGrow: 1,
          p: 3,
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          // height: '100vh',
          height: 'calc(100vh + 1px)',

          overflowY: 'auto'
        }}
      >
        <SearchAndStreamBar />
        <SnackbarProvider
          maxSnack={1}
          Components={{
            loading: ResponseMessage,
            error: ResponseMessage,
            success: ResponseMessage,
            info: ResponseMessage
          }}
        >
          {children}
        </SnackbarProvider>
      </Box>
    </Container>
  );
};

export default MainLayout;
