import { useState } from 'react';
import { ChartSeriesType } from 'pages/Insights/InsightsComponents/types';
import useDateText, { DateTextVariants } from 'components/DateText/useDateText';
import { useTheme } from '@mui/material';
import useTooltip from './useTooltip';
import { Theme } from '@mui/material';
import { TABLE_ROW_HEIGHT_PX } from 'utils/constants';
import createRangeSelector from './useRangeSelector';
const CHART_GRID_COLOR = '#333';
const DEFAULT_CHART_HEIGHT = 450;

const useChartOptions = ({ chartSeries, height }: { chartSeries: any; height?: number }) => {
  const { formatDateString } = useDateText();
  const theme = useTheme();
  const { createTooltip } = useTooltip();
  const createOptions = () => {
    return {
      chart: {
        type: 'line',
        backgroundColor: theme.palette.background.paper,
        height: height ? `${height * TABLE_ROW_HEIGHT_PX + 40}px` : DEFAULT_CHART_HEIGHT,
        style: {
          fontFamily: 'Cairo, sans-serif'
        },
        zoomType: 'x',
        panning: true,
        panKey: 'shift',
        resetZoomButton: createZoomButton(theme)
      },
      xAxis: createXAxis(formatDateString, theme),
      yAxis: createYAxis(theme),
      tooltip: createTooltipOption(theme),
      plotOptions: createPlotOptions(theme),
      series: chartSeries,
      legend: { enabled: false },
      scrollbar: { barBackgroundColor: theme.palette.info.dark },
      navigator: createNavigator(theme, formatDateString),
      rangeSelector: createRangeSelector(theme, chartSeries),
      credits: {
        enabled: false
      }
    };
  };

  function createNavigator(
    theme: Theme,
    formatDateString: (dateString: string, variant: DateTextVariants) => string
  ) {
    return {
      xAxis: {
        gridLineColor: theme.palette.info.dark,
        labels: {
          style: {
            color: theme.palette.text.primary
          },
          formatter: function (): string {
            // @ts-expect-error: highcharts rules.
            const timestamp = this.value;
            return formatDateString(new Date(timestamp).toJSON(), DateTextVariants.MONTH_YEAR);
          }
        }
      },
      enabled: true,
      outlineColor: theme.palette.info.dark,
      handles: {
        backgroundColor: theme.palette.background.default,
        borderColor: theme.palette.info.dark
      }
    };
  }

  function createPlotOptions(theme: Theme) {
    return {
      series: {
        dataLabels: {
          position: 'right',
          enabled: true,
          useHTML: true,
          formatter: function (): string | undefined {
            const point: any = this;
            const last = point.series.points[point.series.points.length - 1].index;
            if (point.point.index === last) {
              return `<div style="color: ${theme.palette.background.default}; display: inline-flex; padding: 0px 4px 0px 4px; justify-content: center;text-align: center;align-items: center;  width:55px;height:15px; color: #070818;font-size: 10px;font-style: normal;font-weight: 400;line-height: normal;background-color:${point.series.color};">${point.y}</div>`;
            }
          }
        }
      },
      line: {
        lineWidth: 2,
        marker: {
          radius: 1,
          enabled: true
        },
        states: {
          hover: {
            lineWidth: 3
          }
        }
      }
    };
  }

  function createTooltipOption(theme: Theme) {
    return {
      formatter: function (): string {
        // @ts-expect-error: highcharts rules.
        const allChartSeries: Highcharts.Series[] = this.series.chart.series;
        // @ts-expect-error: highcharts rules.
        const hoveredPointXValue = this.x;
        return createTooltip(allChartSeries, hoveredPointXValue);
      },
      className: 'tooltip-stock-chart',
      enabled: true,
      shared: true,
      split: false,
      backgroundColor: CHART_GRID_COLOR,
      borderRadius: 0,
      useHTML: true,
      style: {
        color: theme.palette.text.primary,
        fontFamily: 'Cairo, sans-serif'
      }
    };
  }

  function createYAxis(theme: Theme) {
    return {
      offset: 30,
      labels: {
        style: {
          color: theme.palette.text.primary,
          fontSize: '10px'
        },
        formatter: function (): string {
          // @ts-expect-error: highcharts rules.
          return this.value.toFixed(2);
        }
      },
      gridLineColor: CHART_GRID_COLOR
    };
  }

  function createXAxis(
    formatDateString: (dateString: string, variant: DateTextVariants) => string,
    theme: Theme
  ) {
    return {
      overscroll: '2px',
      labels: {
        formatter: function (): string {
          // @ts-expect-error: highcharts rules.
          return formatDateString(new Date(this.value).toJSON(), DateTextVariants.MONTH_YEAR) ?? '';
        },
        style: {
          color: theme.palette.text.primary,
          fontSize: '10px'
        }
      },
      gridLineColor: CHART_GRID_COLOR,
      lineColor: CHART_GRID_COLOR,
      tickColor: CHART_GRID_COLOR
    };
  }

  function createZoomButton(theme: Theme) {
    return {
      position: {
        align: 'left',
        verticalAlign: 'top',
        x: 10,
        y: 0
      },
      relativeTo: 'chart',
      theme: {
        fill: theme.palette.primary.main,
        stroke: 'none',
        r: 0,
        style: {
          color: theme.palette.background.default,
          fontSize: '14px'
        },
        states: {
          hover: {
            fill: theme.palette.primary.light
          }
        }
      }
    };
  }
  return { createOptions };
};

export default useChartOptions;
