import { Dialog, styled } from '@mui/material';
import { ReactNode } from 'react';

const StyledDialog = styled(Dialog)(theme => ({
  '& .MuiDialog-container': {
    background: 'rgba(18, 22, 63, 0.40)',
    backdropFilter: 'blur(1px)'
  },
  '& .MuiDialog-paper': {
    background: 'background.default'
  }
}));

const SmallDialog = ({
  open,
  handleClose,
  children
}: {
  open: boolean;
  handleClose: () => void;
  children: ReactNode;
}) => {
  return (
    <StyledDialog
      open={open}
      onClose={handleClose}
    >
      {children}
    </StyledDialog>
  );
};
export default SmallDialog;
