import { useEffect } from 'react';
import { Box, Button, Container } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'hooks/redux-hooks';
import { loginTrip, selectMemoizedAuthState } from 'slices/authSlice';
import { getTripTokenFromStorage } from 'utils/commonFunctions';
import Loader from 'components/Loader';

const Home = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { status } = useAppSelector(selectMemoizedAuthState);

  const [urlSearchParams] = useSearchParams();
  const urlToken = urlSearchParams.get('token');

  useEffect(() => {
    handleSetup();
  }, [urlToken]);

  const handleSetup = () => {
    const tripToken = getTripTokenFromStorage();
    if (tripToken) navigate('/markets');
    else handleLoginTrip();
  };

  const handleLoginTrip = async () => {
    if (!urlToken) {
      navigate('/login');
    } else {
      const data = await dispatch(loginTrip(urlToken));
      if (data?.meta.requestStatus !== 'rejected') {
        navigate('/markets');
      }
    }
  };

  const handleGoToLoginPage = () => {
    navigate('/login');
  };

  if (status === 'loading' || status === 'fulfilled') {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: '100vh'
        }}
      >
        <Loader />
      </Box>
    );
  }

  if (status === 'failed') {
    return (
      <div className='App-header'>
        <Container
          maxWidth='xs'
          sx={{
            margin: '100px auto',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          Something went wrong.
          <Button
            fullWidth
            variant='contained'
            sx={{ mt: 3, mb: 2 }}
            onClick={handleGoToLoginPage}
          >
            Back to Login page
          </Button>
        </Container>
      </div>
    );
  }

  return null;
};

export default Home;
