import { ReactNode, SyntheticEvent } from 'react';
import { Tabs as MuiTabs, Tab } from '@mui/material';

interface Tab {
  name: string;
  disabled?: boolean;
  label?: ReactNode;
}

interface CustomTabsProps {
  tabs: Tab[];
  activeTab: number;
  setActiveTab: (index: number) => void;
  height?: number;
  minTabWidth?: number;
  maxTabWidth?: number | 'unset';
}

const CustomTabs = ({
  tabs,
  activeTab,
  setActiveTab,
  height = 38,
  minTabWidth = 120,
  maxTabWidth = 'unset',
  ...tabProps
}: CustomTabsProps) => {
  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <MuiTabs
      value={activeTab}
      onChange={handleChange}
      aria-label='tabs'
      variant='scrollable'
      scrollButtons='auto'
      sx={{ minHeight: `${height}px` }}
    >
      {tabs?.map(({ name, label, disabled }) => (
        <Tab
          label={label || name}
          key={name}
          id={`tab-${name}`}
          aria-controls={`tabpanel-${name}`}
          disabled={disabled}
          sx={{
            minWidth: `${minTabWidth}px`,
            maxWidth: `${maxTabWidth}px`,
            height: `${height}px`,
            p: 1,
            '&:not(:last-child)': { mr: '2px' }
          }}
          {...tabProps}
        />
      ))}
    </MuiTabs>
  );
};

export default CustomTabs;
