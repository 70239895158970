import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Button, styled, Typography } from '@mui/material';
import { ApiKeyOutput } from 'slices/apiKeyListSlice';
import DateText from 'components/DateText';
import ConfirmDialog from './ConfirmDialog';
import useApiKeyTable from './useApiKeyTable';
import { DateTextVariants } from 'components/DateText/useDateText';

const StyledTableCell = styled(TableCell)(() => ({
  paddingRight: 30,
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: '50px',
  paddingRight: 1,
  borderBottom: `0.5px solid ${theme.palette.info.light}`,
  '&:last-child tr': { border: 0, borderBottom: 0 }
}));

const StyledHeaderRow = styled(TableRow)(({ theme }) => ({
  height: '50px',
  borderBottom: `1px solid ${theme.palette.text.primary}`
}));

const emptyMessage = (
  <Box sx={{ display: 'flex', width: '100%' }}>
    <Typography
      align='center'
      sx={{ width: '100%', my: 5 }}
    >
      No token generated yet.
    </Typography>
  </Box>
);

const ApiKeyTable = ({ apiKeys }: { apiKeys: ApiKeyOutput[] }) => {
  const { open, setOpen, apiKey, openConfirmModal, deleteKey } = useApiKeyTable();
  return (
    <Box
      sx={() => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%'
      })}
    >
      <ConfirmDialog
        open={open}
        setOpen={setOpen}
        deleteCallback={deleteKey}
      />
      {apiKeys.length ? (
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: 600 }}
            size='small'
            aria-label='api keys table'
          >
            <TableHead>
              <StyledHeaderRow>
                <StyledTableCell>
                  <Typography variant='subtitle2'>Name</Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography variant='subtitle2'>Access Token</Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography variant='subtitle2'>Creation Date</Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography variant='subtitle2'>Last Used</Typography>
                </StyledTableCell>
                <StyledTableCell></StyledTableCell>
              </StyledHeaderRow>
            </TableHead>
            <TableBody>
              {apiKeys.map((apiKey, index) => (
                <StyledTableRow
                  key={apiKey.label}
                  sx={{ '&:last-child': { borderBottom: 0 } }}
                >
                  <StyledTableCell
                    sx={{ width: '25%' }}
                    component='th'
                    scope='row'
                  >
                    <Typography>{apiKey.label}</Typography>
                  </StyledTableCell>
                  <StyledTableCell sx={{ width: '15%' }}>
                    **********{apiKey.lastThreeCharacters}
                  </StyledTableCell>
                  <StyledTableCell sx={{ width: '15%' }}>
                    <DateText
                      dateString={apiKey.creationDate}
                      variant={DateTextVariants.FULL}
                    />
                  </StyledTableCell>
                  <StyledTableCell sx={{ width: '30%' }}>{apiKey.lastUsed ?? ''}</StyledTableCell>
                  <StyledTableCell sx={{ width: '15%' }}>
                    <Button
                      onClick={() => openConfirmModal(apiKey)}
                      sx={{ textDecoration: 'underline' }}
                    >
                      Remove
                    </Button>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        emptyMessage
      )}
    </Box>
  );
};

export default ApiKeyTable;
