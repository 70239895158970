import { Box, Button, useTheme } from '@mui/material';
import {
  FilterList,
  ArrowUpward,
  ArrowDownward,
  UnfoldMore,
  InfoOutlined
} from '@mui/icons-material';
import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { flexRender, Row } from '@tanstack/react-table';
import { useVirtualizer } from '@tanstack/react-virtual';
import CatalogTableRow from './CatalogTableRow';
import TableFilters from './TableFilters';
import DisplaySettingsPopper from './DisplaySettings';
import TableSearch from 'pages/Insights/InsightsComponents/CommoditiesTable/TableSearch';
import { debounce } from 'utils/commonFunctions';
import Tooltip from 'components/Tooltip';

export const FILTER_DRAWER_WIDTH = 262;
const SEARCH_DEBOUNCE_DELAY = 700;

const CatalogTable = ({
  filterOpen,
  setFilterOpen,
  filters,
  setFilters,
  columnFiltersWithCounts,
  rows,
  table,
  filteredData,
  isForwardCurves,
  expandedData,
  setExpandedData,
  showDiscontinued,
  setShowDiscontinued,
  columnVisibility,
  specialFilters,
  setSpecialFilters
}: {
  filterOpen: boolean;
  setFilterOpen: (filterOpen: boolean) => void;
  filters: object;
  setFilters: (filters: object) => void;
  columnFiltersWithCounts: object;
  rows: Row<any>[];
  table: [];
  filteredData: [];
  isForwardCurves: boolean;
  expandedData: boolean;
  setExpandedData: (newValue: boolean) => void;
  columnVisibility: boolean;
  showDiscontinued: boolean;
  setShowDiscontinued: (newValue: boolean) => void;
  specialFilters: object;
  setSpecialFilters: (newFilters: object) => void;
}) => {
  const { palette } = useTheme();
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const [searchValue, setSearchValue] = useState<string>('');
  const [highlightedRowIndex, setHighlightedRowIndex] = useState<number | null>(null);

  useEffect(() => {
    setHighlightedRowIndex(null);
  }, [specialFilters, filters]);

  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    estimateSize: () => 23,
    getScrollElement: () => tableContainerRef.current,
    overscan: 50
  });

  const isAnyFilterApplied =
    Object.values(filters).some(filter => filter && filter.length > 0) ||
    Object.values(specialFilters).some(filter => filter);

  const debouncedHighlightFirstMatch = useMemo(
    () =>
      debounce((inputValue: string) => {
        const matchIndex = rows.findIndex(row => {
          const titleMatch = row.original.Title.toLowerCase().includes(inputValue.toLowerCase());
          const aliasMatch = row.original.Alias?.toLowerCase().includes(inputValue.toLowerCase());
          const codeMatch = row.original.Code?.toLowerCase().includes(inputValue.toLowerCase());

          return titleMatch || aliasMatch || codeMatch;
        });

        if (matchIndex !== -1 && inputValue !== '') {
          setHighlightedRowIndex(matchIndex);
          rowVirtualizer.scrollToIndex(matchIndex);

          // TODO ensure the row is fully visible after the scroll - BUG
          // setTimeout(() => {
          //   const rowElement = document.querySelector(`[data-index="${matchIndex}"]`);
          //   if (rowElement) {
          //     const { top, bottom } = rowElement.getBoundingClientRect();
          //     const isVisible = top >= 0 && bottom <= window.innerHeight;
          //
          //     if (!isVisible) {
          //       rowElement.scrollIntoView({
          //         // behavior: 'smooth',
          //         block: 'top'
          //       });
          //     }
          //   }
          // }, 50);
        } else {
          setHighlightedRowIndex(null);
        }
      }, SEARCH_DEBOUNCE_DELAY),
    [rows, rowVirtualizer, filteredData]
  );

  const handleChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchValue(value);
    debouncedHighlightFirstMatch(value);
  };

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          mb: 1
        }}
      >
        <Box sx={{ mt: 1.5, display: 'flex' }}>
          <Button
            sx={{ width: FILTER_DRAWER_WIDTH - 32, mx: 2 }}
            variant='outlined'
            onClick={() => setFilterOpen(!filterOpen)}
          >
            <Box sx={{ display: 'flex', width: '100%', alignItems: 'center' }}>
              <FilterList sx={{ mr: 0.6 }} />
              {isAnyFilterApplied ? (
                <>
                  Filters Applied
                  <Box
                    sx={{
                      width: 12,
                      height: 12,
                      backgroundColor: 'primary.main',
                      mx: 1
                    }}
                  />
                </>
              ) : (
                'Filter'
              )}
            </Box>
          </Button>
          <DisplaySettingsPopper
            showDiscontinued={showDiscontinued}
            setShowDiscontinued={setShowDiscontinued}
            expandedData={expandedData}
            setExpandedData={setExpandedData}
            table={table}
            isForwardCurves={isForwardCurves}
          />
        </Box>
        <TableSearch
          searchValue={searchValue}
          handleChangeSearch={handleChangeSearch}
        />
      </Box>

      <Box sx={{ display: 'flex' }}>
        <TableFilters
          filterOpen={filterOpen}
          setFilterOpen={setFilterOpen}
          columnFiltersValues={columnFiltersWithCounts}
          onFilterChange={setFilters}
          filteredData={filteredData}
          specialFilters={specialFilters}
          setSpecialFilters={setSpecialFilters}
          isForwardCurves={isForwardCurves}
        />
        <Box
          sx={{
            overflow: 'auto',
            flexGrow: 1,
            ml: filterOpen ? 0 : `${-FILTER_DRAWER_WIDTH}px`,
            transition: 'marginLeft 0.5s ease'
          }}
        >
          {!filteredData.length ? (
            <Box
              sx={{
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              Nothing to display here. Try changing your filters.
            </Box>
          ) : (
            <div
              style={{
                height: 'calc(100vh - 240px)',
                overflowY: 'auto',
                position: 'relative',
                zIndex: 3
              }}
              ref={tableContainerRef}
            >
              <div style={{ display: 'grid' }}>
                <div
                  style={{
                    display: 'grid',
                    position: 'sticky',
                    top: 0,
                    zIndex: 1,
                    background: palette.background.paper
                  }}
                >
                  {table.getHeaderGroups().map(headerGroup => (
                    <div
                      key={headerGroup.id}
                      style={{ display: 'flex', padding: '8px', paddingLeft: '16px' }}
                    >
                      {headerGroup.headers.map(header => (
                        <div
                          key={header.id}
                          style={{
                            display: 'flex',
                            width: header.getSize(),
                            fontWeight: 700,
                            fontSize: '16px',
                            paddingBottom: '0',
                            paddingRight: '8px',
                            justifyContent: ['Last', 'Change', 'Periods'].includes(
                              header.column.columnDef.header as string
                            )
                              ? 'flex-end'
                              : 'flex-start'
                          }}
                        >
                          {/* eslint-disable-next-line
                        jsx-a11y/click-events-have-key-events,
                        jsx-a11y/no-static-element-interactions */}
                          <div
                            style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                            onClick={header.column.getToggleSortingHandler()}
                          >
                            {flexRender(header.column.columnDef.header, header.getContext())}
                            {header.column.columnDef.header === 'Factsheet Link' && (
                              <Tooltip title='Factsheets provide details of the methodology behind the index.'>
                                <InfoOutlined sx={{ ml: 1, fontSize: 16 }} />
                              </Tooltip>
                            )}
                            {(header.column.columnDef.header !== 'Factsheet Link' &&
                              {
                                asc: (
                                  <ArrowUpward
                                    sx={{ ml: 0.5 }}
                                    fontSize='small'
                                  />
                                ),
                                desc: (
                                  <ArrowDownward
                                    sx={{ ml: 0.5 }}
                                    fontSize='small'
                                  />
                                )
                              }[header.column.getIsSorted() as string]) ?? (
                              <UnfoldMore
                                sx={{ ml: 0.5 }}
                                fontSize='small'
                              />
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
                <div style={{ height: `${rowVirtualizer.getTotalSize()}px`, position: 'relative' }}>
                  {rowVirtualizer.getVirtualItems().map(virtualRow => {
                    const row = rows[virtualRow.index];
                    return (
                      <CatalogTableRow
                        columnVisibility={columnVisibility}
                        isForwardCurves={isForwardCurves}
                        highlightedRowIndex={highlightedRowIndex}
                        setHighlightedRowIndex={setHighlightedRowIndex}
                        virtualRow={virtualRow}
                        row={row}
                        key={row.id}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default CatalogTable;
