import { Fragment } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { CommoditiesTableRowType, CommoditiesTableSectionType } from '../../types';
import CommoditiesTableRow from './CommoditiesTableRow';
import Checkbox from 'components/Checkbox';
import { TABLE_ROW_HEIGHT_PX } from 'utils/constants';

const CommoditiesTableTab = ({
  sections,
  selection,
  allCodesSelected,
  toggleCodeInSelection,
  toggleSectionInSelection,
  addAllCodesToSelection,
  clearSelection,
  highlightedRowId,
  height
}: {
  sections: CommoditiesTableSectionType[];
  selection: string[];
  allCodesSelected: boolean;
  toggleCodeInSelection: (code: string, codeSelected: boolean) => void;
  toggleSectionInSelection: (rows: CommoditiesTableRowType[], sectionSelected: boolean) => void;
  addAllCodesToSelection: () => void;
  clearSelection: () => void;
  highlightedRowId: string;
  height?: number;
}) => {
  const renderEmptyCells = (count: number) =>
    Array.from({ length: count }).map((_, index) => <TableCell key={index} />);

  return (
    <TableContainer
      sx={{
        width: '100%',
        height: 'fit-content',
        maxHeight: height ? `${height * TABLE_ROW_HEIGHT_PX + 47}px` : 'fit-content'
      }}
    >
      <Table
        aria-label='commodities table'
        size='small'
        stickyHeader
      >
        <TableHead>
          <TableRow
            sx={theme => ({
              '& td, & th': {
                pb: 1,
                borderBottom: `0.5px solid ${theme.palette.text.primary}`,
                backgroundColor: 'background.paper'
              }
            })}
          >
            <TableCell sx={{ pl: 2, pr: 1, width: 24 }}>
              <Checkbox
                checked={allCodesSelected}
                onChange={allCodesSelected ? clearSelection : addAllCodesToSelection}
                sx={{ mb: '3px' }}
                inputProps={{
                  'aria-label': 'select all'
                }}
              />
            </TableCell>
            <TableCell
              component='th'
              scope='row'
              sx={{
                width: '33%'
              }}
            >
              Name
            </TableCell>
            <TableCell align='right'>Date</TableCell>
            <TableCell align='right'>Last</TableCell>
            <TableCell align='right'>Change</TableCell>
            <TableCell align='right'>Diff</TableCell>
            <TableCell
              align='right'
              sx={{ pr: 2 }}
            >
              Change
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody
          sx={{
            '& .MuiTableRow-root:nth-of-type(even)': {
              backgroundColor: 'secondary.main'
            }
          }}
        >
          {sections?.map(({ name, rows }) => {
            const sectionSelected = rows.every(row =>
              selection.includes(
                JSON.stringify({
                  shortTitle: row.shortTitle,
                  key: row.symbol.key,
                  diffKey: row.diffSymbol.key
                })
              )
            );
            return (
              <Fragment key={name}>
                {name && (
                  <TableRow sx={{ '&:first-of-type td, &:first-of-type th': { pt: 1 } }}>
                    <TableCell sx={{ p: 0, pl: 2 }}>
                      <Checkbox
                        sx={{ mb: '4px' }}
                        inputProps={{
                          'aria-label': 'select all section'
                        }}
                        checked={sectionSelected}
                        onChange={() => toggleSectionInSelection(rows, sectionSelected)}
                      />
                    </TableCell>
                    <TableCell sx={{ fontWeight: 700 }}>{name}</TableCell>
                    {renderEmptyCells(5)}
                  </TableRow>
                )}
                {rows?.map(row => (
                  <CommoditiesTableRow
                    key={row.shortTitle}
                    row={row}
                    selection={selection}
                    toggleCodeInSelection={toggleCodeInSelection}
                    highlightedRowId={highlightedRowId}
                  />
                ))}
              </Fragment>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CommoditiesTableTab;
