import { Button, DialogActions, DialogContent, Typography } from '@mui/material';
import Loader from 'components/Loader';
import useGenerationBody from './useGenerationBody';

const PostGenerationBody = ({ handleClose }: { handleClose: () => void }) => {
  const { listApiKeyState, toClipboard, exportToCsv } = useGenerationBody();

  return (
    <>
      {listApiKeyState.status === 'failed' ? (
        <>
          <DialogContent>
            <Typography>Error generating ApiKey</Typography>
            <Typography>Please contact the General Index support team.</Typography>
          </DialogContent>
          <DialogActions>
            <Button
              sx={{ mr: 3, p: 0, minWidth: '40px' }}
              variant='text'
              onClick={handleClose}
            >
              Close
            </Button>
          </DialogActions>
        </>
      ) : listApiKeyState.status === 'loading' ? (
        <Loader />
      ) : (
        <>
          <DialogContent sx={{ paddingBottom: 3 }}>
            <Typography
              paddingBottom={'4px'}
              variant='subtitle2'
            >
              Token was generated succesfully.
            </Typography>
            <Typography
              variant='caption'
              sx={theme => ({
                fontWeight: '500',
                color: theme.palette.info.dark
              })}
            >
              If you lose or forget the key you cannot retrieve it. Instead create and copy new API
              key.
            </Typography>
          </DialogContent>
          <DialogActions sx={{ p: 3 }}>
            <Button
              sx={{ mr: 3, pr: 0, minWidth: 40 }}
              variant='text'
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              sx={{ mr: 3 }}
              variant='outlined'
              onClick={exportToCsv}
            >
              Download .csv file
            </Button>
            <Button
              variant='contained'
              onClick={toClipboard}
            >
              Copy key
            </Button>
          </DialogActions>
        </>
      )}
    </>
  );
};

export default PostGenerationBody;
