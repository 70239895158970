import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { Box, Button, Typography } from '@mui/material';
import useSettingsChanger from './useSettingChanger';
import { Option } from 'slices/userConfigSlice';

const SettingChanger = ({
  label,
  userSetting,
  onChange,
  options
}: {
  label: string;
  userSetting: Option;
  onChange: (value: Option) => void;
  options: Option[];
}) => {
  const { popperAnchorRef, selectedIndex, open, handleMenuItemClick, handleToggle, handleClose } =
    useSettingsChanger({ options, onChange, userSetting });
  return (
    <Box
      component={Paper}
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
        p: 3,
        width: '100%'
      }}
    >
      <Typography
        sx={{ width: '15%' }}
        variant='body2'
      >
        {label}
      </Typography>
      <Typography
        sx={{ width: '30%' }}
        variant={'subtitle2'}
      >
        {userSetting.label}
      </Typography>
      <Box ref={popperAnchorRef}>
        <Button
          variant='outlined'
          onClick={handleToggle}
        >
          Change
        </Button>
        <Popper
          sx={theme => ({ zIndex: 1, backgroundColor: theme.palette.background.paper })}
          open={open}
          anchorEl={popperAnchorRef.current}
          role={undefined}
          transition
          disablePortal
          placement='bottom-end'
        >
          {({ TransitionProps }) => (
            <Box
              sx={theme => ({
                overflow: 'auto',
                minWidth: '340px',
                maxHeight: '320px',
                borderLeft: `1px solid ${theme.palette.primary.main}`,
                borderBottom: `1px solid ${theme.palette.primary.main}`
              })}
            >
              <Grow {...TransitionProps}>
                <Paper sx={{ p: 0 }}>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      sx={{ p: 0 }}
                      id='settings-changer-menu'
                      autoFocusItem
                    >
                      {options.map((option, index) => (
                        <MenuItem
                          key={option.value}
                          selected={index === selectedIndex}
                          onClick={() => handleMenuItemClick(index)}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            </Box>
          )}
        </Popper>
      </Box>
    </Box>
  );
};

export default SettingChanger;
