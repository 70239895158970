import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { RootState } from '../store';
import httpInstances from 'api/axiosInstance';
const gxGoToken = import.meta.env.VITE_GX_GO_TOKEN;
type InsightsListState = {
  status: 'idle' | 'loading' | 'failed' | 'fulfilled';
  error: string | null;
  insightsList: insightsListItem[];
};

type ErrorResponse = {
  message: string;
};

const initialState: InsightsListState = {
  status: 'idle',
  error: null,
  insightsList: []
};

interface insightsListItem {
  insightId: string;
  metadata: {
    displayName: string;
    commodity: string;
    region: string;
  };
}

export const getInsightsList = createAsyncThunk(
  'getInsightsList',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await httpInstances.axiosGoInstance.get('/insights', {
        headers: { 'x-gx-go-token': gxGoToken }
      });
      return data;
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;
        return rejectWithValue(errorResponse);
      }
      throw error;
    }
  }
);

const insightsListSlice = createSlice({
  name: 'insights',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getInsightsList.pending, state => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(getInsightsList.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.insightsList = action.payload;
      })
      .addCase(getInsightsList.rejected, (state, action) => {
        state.status = 'failed';
        if (action.payload) {
          state.error = (action.payload as ErrorResponse).message || 'Request failed';
        } else {
          state.error = action.error.message || 'Request failed';
        }
      });
  }
});

export const selectInsightsListStatus = (state: RootState) => state.insightsList.status;
export default insightsListSlice.reducer;
