import { useEffect, useState } from 'react';

const useChartsBlock = () => {
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    setActiveTab(0);
  }, []);

  return {
    activeTab,
    setActiveTab
  };
};

export default useChartsBlock;
