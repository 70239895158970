import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Box,
  CircularProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper
} from '@mui/material';
import { RootState } from '../../../store';
import { useAppSelector } from 'hooks/redux-hooks';
import { selectInsightsListStatus } from 'slices/insightsListSlice';

const InsightsDropdown = () => {
  const status = useAppSelector(selectInsightsListStatus);
  const { insightsList } = useSelector((state: RootState) => state.insightsList);
  const location = useLocation();
  const urlInsightId = location.pathname.split('/').filter(Boolean).pop();

  if (status === 'loading' || status === 'idle')
    return (
      <Paper
        sx={{
          height: 200,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <CircularProgress />
      </Paper>
    );

  return (
    <List
      sx={{
        backgroundColor: 'background.paper',
        height: 'fit-content',
        maxHeight: 410,
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      {insightsList.map(({ metadata: { displayName }, insightId }) => (
        <ListItem
          disablePadding
          key={displayName}
        >
          <ListItemButton
            selected={urlInsightId === insightId}
            sx={{ px: 3, py: 0 }}
            component={Link}
            to={`/markets/${insightId}`}
          >
            <ListItemText primary={displayName} />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
};

export default InsightsDropdown;
