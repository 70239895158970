import { Box, Paper } from '@mui/material';
import useChartsBlock from './useChartsBlock';
import { ChartsBlockTabType } from 'pages/Insights/InsightsComponents/types';
import Tabs from 'components/Tabs';
import TabPanel from 'components/TabPanel';
import ChartContainer from './ChartContainer';

const ChartsBlock = ({
  tabs,
  layout
}: {
  tabs: ChartsBlockTabType[];
  layout?: { fullWidth?: boolean; height?: number };
}) => {
  const { activeTab, setActiveTab } = useChartsBlock();

  const { fullWidth, height } = layout || {};

  return (
    <Paper
      sx={theme => ({
        width: fullWidth ? '100%' : 'calc(50% - 12px)',
        [theme.breakpoints.down(1350)]: {
          width: '100%'
        }
      })}
    >
      <Tabs
        tabs={tabs.map(({ name }) => ({ name }))}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      {tabs.map(({ name, series }, index) => (
        <TabPanel
          key={name}
          index={index}
          value={activeTab}
        >
          <Box sx={{ mt: 1 }}>
            <ChartContainer
              templateChartSeries={series}
              height={height}
            />
          </Box>
        </TabPanel>
      ))}
    </Paper>
  );
};

export default ChartsBlock;
