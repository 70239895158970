import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  getCoreRowModel,
  getSortedRowModel,
  OnChangeFn,
  useReactTable
} from '@tanstack/react-table';
import { RootState } from '../../store';
import {
  defaultVisibilityState,
  energyTransitionModules,
  forwardCurvesColumns,
  oilOnlyModules,
  spotColumns
} from './constants';
import { useAppDispatch } from 'hooks/redux-hooks';
import { CatalogItem, getCatalog } from 'slices/catalogSlice';

const useCatalog = () => {
  const dispatch = useAppDispatch();

  const [activeTab, setActiveTab] = useState<number>(0);

  const isForwardCurves = !!activeTab;

  const [showDiscontinued, setShowDiscontinued] = useState<boolean>(false);
  const [expandedData, setExpandedData] = useState<boolean>(false);
  const [filterOpen, setFilterOpen] = useState<boolean>(false);
  const [filters, setFilters] = useState<{ [key: string]: string[] }>({});
  const [sorting, setSorting] = useState(isForwardCurves ? [] : [{ id: 'Alias', desc: false }]);
  const [specialFilters, setSpecialFilters] = useState<{ [key: string]: boolean }>({
    dataAccessOnly: false,
    oilOnly: false,
    energyTransitionOnly: false
  });
  const [columnVisibility, setColumnVisibility] = useState(defaultVisibilityState);

  useEffect(() => {
    fetchCatalog();
  }, [activeTab]);

  const fetchCatalog = async () => {
    dispatch(getCatalog(isForwardCurves));
  };

  const { catalog } = useSelector((state: RootState) => state.catalog);

  useEffect(() => {
    clearState();
  }, [activeTab]);

  const clearState = () => {
    setFilters({});
    setSpecialFilters({
      dataAccessOnly: false,
      oilOnly: false,
      energyTransitionOnly: false
    });
    setFilterOpen(false);
    setColumnVisibility(
      isForwardCurves
        ? prevState => ({
            ...prevState,
            TimeRef: true,
            PeriodType: true
          })
        : defaultVisibilityState
    );
    setExpandedData(false);
  };

  const columns = isForwardCurves ? forwardCurvesColumns : spotColumns;

  const filteredData = useMemo(() => {
    if (!catalog) return [];

    const filteredByStatus = catalog.filter(
      catalogItem => showDiscontinued || catalogItem.Status !== 'Archive'
    );

    const filteredByFilters = filteredByStatus.filter(catalogItem =>
      Object.keys(filters).every(column => {
        const selectedValues = filters[column];
        if (!selectedValues || selectedValues.length === 0) return true;
        return selectedValues.includes(catalogItem[column]);
      })
    );

    const filteredBySpecialFilters = filteredByFilters.filter(catalogItem => {
      const { IndexModule, Last, Change } = catalogItem;

      const dataAccessCondition = !specialFilters.dataAccessOnly || (!!Last && !!Change);

      const oilOnlyCondition =
        !specialFilters.oilOnly || oilOnlyModules.some(module => IndexModule.includes(module));

      const energyTransitionCondition =
        !specialFilters.energyTransitionOnly ||
        energyTransitionModules.some(module => IndexModule.includes(module));

      return dataAccessCondition && oilOnlyCondition && energyTransitionCondition;
    });

    if (expandedData || isForwardCurves) return filteredBySpecialFilters;

    const uniqueRowsByCode: { [code: string]: CatalogItem } = {};

    filteredBySpecialFilters.forEach(catalogItem => {
      const existingItem = uniqueRowsByCode[catalogItem.Code];
      if (!existingItem) {
        uniqueRowsByCode[catalogItem.Code] = catalogItem;
      } else {
        if (
          catalogItem.TimeRef > existingItem.TimeRef ||
          (catalogItem.TimeRef === existingItem.TimeRef && catalogItem.PeriodType === 'Prompt') ||
          (catalogItem.PeriodType === existingItem.PeriodType && catalogItem.Period === 1)
        ) {
          uniqueRowsByCode[catalogItem.Code] = catalogItem;
        }
      }
    });
    return Object.values(uniqueRowsByCode);
  }, [catalog, filters, showDiscontinued, expandedData, columnVisibility, specialFilters]);

  const columnFiltersWithCounts = useMemo(() => {
    const getCounts = (key: string): { label: string; count: number }[] => {
      const counts: { [label: string]: number } = {};

      filteredData?.forEach(catalogItem => {
        const label = catalogItem[key];
        if (counts[label]) {
          counts[label]++;
        } else {
          counts[label] = 1;
        }
      });

      return Object.entries(counts)
        .map(([label, count]) => ({ label, count }))
        .sort((a, b) => b.count - a.count);
    };

    return {
      Commodity: getCounts('Commodity'),
      TradingHub: getCounts('TradingHub'),
      DeliveryBasis: getCounts('DeliveryBasis'),
      PricingBasis: getCounts('PricingBasis'),
      Units: getCounts('Units'),
      Currency: getCounts('Currency'),
      Frequency: getCounts('Frequency')
      // IndexModule: getCounts('IndexModule')
    };
  }, [catalog, showDiscontinued, expandedData, specialFilters]);

  const table = useReactTable({
    data: filteredData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
    state: {
      columnVisibility,
      sorting
    },
    onColumnVisibilityChange: setColumnVisibility as OnChangeFn<any>,
    onSortingChange: setSorting
  });

  const { rows } = table.getRowModel();

  return {
    filterOpen,
    setFilterOpen,
    columnFiltersWithCounts,
    rows,
    setFilters,
    filteredData,
    table,
    activeTab,
    setActiveTab,
    isForwardCurves,
    filters,
    showDiscontinued,
    setShowDiscontinued,
    expandedData,
    setExpandedData,
    columnVisibility,
    specialFilters,
    setSpecialFilters
  };
};

export default useCatalog;
