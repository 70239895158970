import { useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';
import { useEffect } from 'react';
import { RootState } from '../../store';
import InsightsSkeleton from './InsightsSkeleton';
import InsightsComponents from './InsightsComponents';
import useInsights from './useInsights';
import { useAppSelector } from 'hooks/redux-hooks';
import { selectInsightsStatus } from 'slices/insightsSlice';
import MainLayout from 'layouts/MainLayout';
import Tabs from 'components/Tabs';

const Insights = () => {
  const status = useAppSelector(selectInsightsStatus);
  const { insights } = useSelector((state: RootState) => state.insights);
  const { activeTab, setActiveTab } = useInsights();

  useEffect(() => {
    if (insights?.metadata?.displayName) {
      document.title = 'GX Go | ' + insights.metadata.displayName;
    }
    return () => {
      document.title = 'GX Go';
    };
  }, [insights]);

  return (
    <MainLayout>
      {insights?.pages && status !== 'loading' && status !== 'idle' ? (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', mt: 3 }}>
            <Typography
              variant='h2'
              sx={{ mr: 6 }}
            >
              {insights?.metadata?.displayName}
            </Typography>
            <Tabs
              tabs={insights?.pages.map(({ name }) => ({ name }))}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          </Box>
          <InsightsComponents components={insights?.pages[activeTab]?.components} />
        </>
      ) : (
        <InsightsSkeleton />
      )}
    </MainLayout>
  );
};

export default Insights;
