import { Box, Typography } from '@mui/material';
import useStockChart from './useStockChart';
import { ChartSeriesType } from 'pages/Insights/InsightsComponents/types';
import Loader from 'components/Loader';
import StockChart from './StockChart';

import './style.css';
const DEFAULT_CHART_HEIGHT = 450;

const ChartContainer = ({
  templateChartSeries,
  height
}: {
  templateChartSeries: ChartSeriesType[];
  height?: number;
}) => {
  const { loading, error, options } = useStockChart(templateChartSeries, height);

  if (error)
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          height: DEFAULT_CHART_HEIGHT
        }}
      >
        <Typography color='info'>
          An error occurred while retrieving your data. Our team is looking into it.
        </Typography>
        <Typography color='info'>Please try again shortly.</Typography>
      </Box>
    );

  if (loading)
    return (
      <Box sx={{ height: DEFAULT_CHART_HEIGHT }}>
        <Loader />
      </Box>
    );

  return (
    <Box
      className='chart-container'
      sx={{ position: 'relative ' }}
    >
      <StockChart options={options} />
    </Box>
  );
};
export default ChartContainer;
