import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import {
  CommoditiesTableTabType,
  CommoditiesTableRowType
} from 'pages/Insights/InsightsComponents/types';
import { debounce } from 'utils/commonFunctions';

const SEARCH_DEBOUNCE_DELAY = 500;

const useCommoditiesTable = (tabs: CommoditiesTableTabType[]) => {
  const [activeTab, setActiveTab] = useState<number>(0);
  const [selection, setSelection] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [highlightedRowId, setHighlightedRowId] = useState<string>('');

  useEffect(() => {
    clearSelection();
  }, [activeTab]);

  const toggleCodeInSelection = (code: string, codeSelected: boolean): void => {
    if (codeSelected) removeCodeFromSelection(code);
    else addCodeToSelection(code);
  };

  const addCodeToSelection = (code: string) => {
    setSelection([...selection, code]);
  };

  const removeCodeFromSelection = (code: string) => {
    const newSelection = [...selection];
    newSelection.splice(newSelection.indexOf(code), 1);
    setSelection(newSelection);
  };

  const toggleSectionInSelection = (
    rows: CommoditiesTableRowType[],
    sectionSelected: boolean = false
  ) => {
    if (sectionSelected) removeSectionFromSelection(rows);
    else addSectionToSelection(rows);
  };

  const removeSectionFromSelection = (rows: CommoditiesTableRowType[]) => {
    const newSelection = [...selection];
    for (const row of rows) {
      const uniqueId = JSON.stringify({
        shortTitle: row.shortTitle,
        key: row.symbol.key,
        diffKey: row.diffSymbol.key
      });
      const index = newSelection.findIndex(selectionCode => selectionCode === uniqueId);
      newSelection.splice(index, 1);
    }
    setSelection(newSelection);
  };

  const addSectionToSelection = (rows: CommoditiesTableRowType[]) => {
    const codesToAdd = [];
    for (const row of rows) {
      const uniqueId = JSON.stringify({
        shortTitle: row.shortTitle,
        key: row.symbol.key,
        diffKey: row.diffSymbol.key
      });
      if (!selection.includes(uniqueId)) {
        codesToAdd.push(uniqueId);
      }
    }
    setSelection([...selection, ...codesToAdd]);
  };

  const addAllCodesToSelection = () => {
    let newSelection = [...selection];

    tabs[activeTab].sections.forEach(({ rows }) => {
      const codesToAdd = rows
        .map(row =>
          JSON.stringify({
            shortTitle: row.shortTitle,
            key: row.symbol.key,
            diffKey: row.diffSymbol.key
          })
        )
        .filter(code => !newSelection.includes(code));
      newSelection = [...newSelection, ...codesToAdd];
    });
    setSelection(newSelection);
  };

  const allCodesSelected = tabs[activeTab].sections.every(({ rows }) =>
    rows.every(row =>
      selection.includes(
        JSON.stringify({
          shortTitle: row.shortTitle,
          key: row.symbol.key,
          diffKey: row.diffSymbol.key
        })
      )
    )
  );

  const clearSelection = () => {
    setSelection([]);
  };

  const handleDebouncedSearch = useMemo(
    () =>
      debounce(value => {
        if (value === '') {
          setHighlightedRowId('');
          return;
        }
        findSearchValueInTabs(value as string);
      }, SEARCH_DEBOUNCE_DELAY),
    [tabs]
  );

  const findSearchValueInTabs = (value: string) => {
    tabs.some(({ sections }, tabIndex) => {
      return sections.some(({ rows }) => {
        return rows.some(({ shortTitle, symbol: { key }, diffSymbol: { key: diffKey } }) => {
          const uniqueRowId = JSON.stringify({ shortTitle, key, diffKey });
          if (shortTitle.toLowerCase().includes(value.toLowerCase())) {
            setActiveTab(tabIndex);
            setHighlightedRowId(uniqueRowId);
            return true;
          }
          return false;
        });
      });
    });
  };

  const handleChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchValue(value);
    handleDebouncedSearch(value);
  };

  return {
    activeTab,
    setActiveTab,
    selection,
    toggleCodeInSelection,
    toggleSectionInSelection,
    addAllCodesToSelection,
    allCodesSelected,
    clearSelection,
    searchValue,
    handleChangeSearch,
    highlightedRowId
  };
};

export default useCommoditiesTable;
