import { Box, ButtonBase, Divider, Link, Typography } from '@mui/material';
import Checkbox from 'components/Checkbox';

const buttonBaseStyles = {
  px: 1,
  mx: 1
};

const SelectionMenu = ({
  selection,
  addAllCodesToSelection,
  allCodesSelected,
  clearSelection
}: {
  selection: string[];
  addAllCodesToSelection: () => void;
  allCodesSelected: boolean;
  clearSelection: () => void;
}) => (
  <Box
    sx={{
      backgroundColor: 'background.additionalPaper',
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      height: 48,
      px: 2,
      py: 1
    }}
  >
    <Checkbox
      checked
      onClick={clearSelection}
    />
    <Typography
      component='div'
      sx={{ mx: 1, mb: 0.25, width: 72 }}
    >
      {selection.length} Item{selection.length > 1 && 's'}
    </Typography>
    <Divider orientation='vertical' />
    <ButtonBase
      sx={buttonBaseStyles}
      onClick={addAllCodesToSelection}
      disabled={allCodesSelected}
    >
      Select all
    </ButtonBase>
    <Divider orientation='vertical' />
    <ButtonBase
      sx={buttonBaseStyles}
      onClick={clearSelection}
    >
      Remove
    </ButtonBase>
    <Divider orientation='vertical' />
    <ButtonBase
      sx={buttonBaseStyles}
      disabled // TODO v2
    >
      Add to Launchpad
    </ButtonBase>
    {selection.length === 1 && (
      <>
        <Divider orientation='vertical' />
        <Link
          href={`https://factsheets.g-x.co/${JSON.parse(selection[0])?.key?.Code || JSON.parse(selection[0])?.diffKey?.Code}.pdf`}
          target='_blank'
          rel='noopener noreferrer'
          color='text.primary'
          sx={{ ...buttonBaseStyles, textDecoration: 'none', fontSize: 14 }}
        >
          Factsheet
        </Link>
      </>
    )}
  </Box>
);

export default SelectionMenu;
