import { useEffect, useRef } from 'react';
import { Box, Link, TableCell, TableRow, useTheme } from '@mui/material';
import { LockOutlined } from '@mui/icons-material';
import { CommoditiesTableRowType } from '../../../types';
import Checkbox from 'components/Checkbox';
import { getDiffColor } from 'utils/commonFunctions';
import DateText from 'components/DateText';
import { DateTextVariants } from 'components/DateText/useDateText';
import Tooltip from 'components/Tooltip';

const CommoditiesTableRow = ({
  row,
  selection,
  toggleCodeInSelection,
  highlightedRowId
}: {
  row: CommoditiesTableRowType;
  selection: string[];
  toggleCodeInSelection: (code: string, codeSelected: boolean) => void;
  highlightedRowId: string;
}) => {
  const theme = useTheme();
  const {
    shortTitle,
    date,
    last,
    delta,
    diff,
    diffDelta,
    symbol: { key },
    diffSymbol: { key: diffKey }
  } = row;

  const restrictedData = !last && !diff;
  const uniqueRowId = JSON.stringify({ shortTitle, key, diffKey });
  const rowRef = useRef<HTMLTableRowElement | null>(null);
  const checked = selection.includes(uniqueRowId);
  const selectedRow = highlightedRowId && uniqueRowId === highlightedRowId;

  const returnBackgroundColor = () => {
    if (selectedRow) return '#31475A';
    if (restrictedData) return theme.palette.table.infoRowBg;
    return 'inherit';
  };

  const restrictedIconWithTooltip = (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
      <Tooltip
        title={
          <>
            You do not have access to this data.{' '}
            <Link
              href='https://www.general-index.com/contact-us'
              target='_blank'
              rel='noopener noreferrer'
              color='text.primary'
              sx={{ fontWeight: 700 }}
            >
              Request access
            </Link>
          </>
        }
      >
        <LockOutlined
          sx={{ fontSize: '15px' }}
          color='#cccccc'
        />
      </Tooltip>
    </Box>
  );

  useEffect(() => {
    if (selectedRow && rowRef.current) {
      rowRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  }, [selectedRow]);

  return (
    <TableRow
      key={shortTitle}
      ref={rowRef}
      sx={{
        '&:last-child td, &:last-child th': { pb: 1 },
        '& td, & th': { backgroundColor: returnBackgroundColor() },
        '&:first-of-type td, &:first-of-type th': { pt: 1 }
      }}
    >
      <TableCell sx={{ p: 0, pl: 2 }}>
        <Checkbox
          checked={checked}
          sx={{ mb: '3px' }}
          inputProps={{
            'aria-label': 'select row'
          }}
          onChange={() => toggleCodeInSelection(uniqueRowId, checked)}
        />
      </TableCell>
      <TableCell
        component='th'
        scope='row'
        sx={{
          display: 'flex',
          alignItems: 'center'
        }}
      >
        {shortTitle}
      </TableCell>
      <TableCell align='right'>
        <DateText
          dateString={date}
          variant={DateTextVariants.DAY_MONTH}
        />
      </TableCell>
      <TableCell align='right'>{restrictedData ? restrictedIconWithTooltip : last}</TableCell>
      <TableCell
        align='right'
        sx={{ color: getDiffColor(Number(delta)) }}
      >
        {delta}
      </TableCell>
      <TableCell align='right'>{restrictedData ? restrictedIconWithTooltip : diff}</TableCell>
      <TableCell
        align='right'
        sx={{ color: getDiffColor(Number(diffDelta)), pr: 2 }}
      >
        {diffDelta}
      </TableCell>
    </TableRow>
  );
};

export default CommoditiesTableRow;
