export const getTripTokenFromStorage = () => sessionStorage.getItem('tripToken');

export const setTripTokenToStorage = (tripToken: string): void => {
  sessionStorage.setItem('tripToken', tripToken);
};

export const getUserEmailFromStorage = () => sessionStorage.getItem('userEmail');

export const setUserEmailToStorage = (userEmail: string): void => {
  sessionStorage.setItem('userEmail', userEmail);
};

export const getGxTokenFromStorage = () => localStorage.getItem('gxToken');

export const setGxTokenToStorage = (gxToken: string): void => {
  localStorage.setItem('gxToken', gxToken);
};

export const clearStoragesFromTokensAndUserInfo = (): void => {
  sessionStorage.removeItem('tripToken');
  sessionStorage.removeItem('userEmail');
  localStorage.removeItem('gxToken');
};

export const getDiffColor = (value: number | null) => {
  if (value === 0) return '#778899';
  if (!value) return '#f8f8f8';
  return Math.sign(value) > 0 ? '#00E676' : '#FF1744';
};

export const generateUUID = (): string =>
  '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, (c: string) =>
    (
      parseInt(c) ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (parseInt(c) / 4)))
    ).toString(16)
  );

export const debounce = (func: (...args: any[]) => void, delay: number) => {
  let timeout: NodeJS.Timeout;
  return (...args: unknown[]) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

export const downloadAsCsv = (values: string[]) => {
  const csv = values.join('\n');
  const blob = new Blob([csv], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  const anchorElemnt = document.createElement('a');
  anchorElemnt.setAttribute('href', url);
  anchorElemnt.setAttribute('download', 'key.csv');
  anchorElemnt.click();
};

export const transformCamelCaseToSpaces = (text: string) => {
  return text.replace(/([a-z])([A-Z])/g, '$1 $2');
};

export const sleep = async (ms: number): Promise<void> =>
  new Promise(resolve => setTimeout(resolve, ms));
