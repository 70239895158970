export const oilOnlyModules = [
  'AfricaCrude',
  'AmericasMarine',
  'AsiaBiofuels',
  'AsiaCrude',
  'AsiaFeedstocks',
  'AsiaFuelOil',
  'AsiaGasoil',
  'AsiaGasoline',
  'AsiaJet',
  'AsiaLPG',
  'AsiaMarine',
  'AsiaNaphtha',
  'EuropeanBiofuels',
  'EuropeanCrude',
  'EuropeanFeedstocks',
  'EuropeanFuelOil',
  'EuropeanGasoil',
  'EuropeanGasoline',
  'EuropeanJet',
  'EuropeanLPG',
  'EuropeanMarine',
  'EuropeanNaphtha',
  'GlobalIndexes',
  'GlobalOilSelect',
  'MiddleEastCrude',
  'MiddleEastFuelOil',
  'MiddleEastGasoil',
  'MiddleEastGasoline',
  'MiddleEastJet',
  'MiddleEastNaphtha',
  'NorthAmericaBiofuels',
  'NorthAmericaCrude',
  'NorthAmericaDistillate',
  'NorthAmericaFeedstocks',
  'NorthAmericaFuelOil',
  'NorthAmericaGasoline',
  'NorthAmericaJet',
  'NorthAmericaNaphtha',
  'NorthAmericaNGLs',
  'NorthAmericaStorage',
  'SouthAmericaCrude'
];

export const energyTransitionModules = [
  'AsiaBiofuels',
  'EuropeanBiofuels',
  'GlobalHydrogen',
  'GlobalMarineCarbon',
  'GlobalVoluntaryCarbon',
  'NorthAmericaBiofuels'
];

export const spotColumns = [
  { accessorKey: 'Code', header: 'Code', size: 100 },
  { accessorKey: 'Alias', header: 'Alias' },
  { accessorKey: 'Title', header: 'Title', size: 650 },
  { accessorKey: 'TimeRef', header: 'TimeRef', size: 95 },
  { accessorKey: 'PeriodType', header: 'PeriodType', size: 120 },
  { accessorKey: 'Period', header: 'Period', size: 100 },
  { accessorKey: 'LastDate', header: 'Last Date', size: 100 },
  { accessorKey: 'Last', header: 'Last', size: 100 },
  { accessorKey: 'Change', header: 'Change', size: 100 },
  { accessorKey: 'Units', header: 'Units', size: 80 },
  { accessorKey: 'Currency', header: 'Currency', size: 100 },
  { accessorKey: 'Commodity', header: 'Commodity', size: 120 },
  { accessorKey: 'TradingHub', header: 'Trading Hub', size: 120 },
  { accessorKey: 'PricingBasis', header: 'Pricing Basis', size: 150 },
  { accessorKey: 'DeliveryBasis', header: 'Delivery Basis', size: 150 },
  { accessorKey: 'Frequency', header: 'Frequency', size: 120 },
  { accessorKey: 'IndexModule', header: 'Index Module', size: 200 },
  { accessorKey: 'StartDate', header: 'StartDate', size: 120 },
  { accessorKey: 'EndDate', header: 'EndDate', size: 120 },
  { accessorKey: 'FactsheetVersion', header: 'Factsheet Version', size: 170 },
  { accessorKey: 'TimeZone', header: 'Time Zone', size: 140 },
  { accessorKey: 'HolidayCalendar', header: 'Holiday Calendar', size: 220 },
  { accessorKey: 'Increment', header: 'Increment', size: 110 },
  { accessorKey: 'Index', header: 'Columns Available', size: 170 },
  { accessorKey: 'Factsheet', header: 'Factsheet Link', size: 135 }
];

export const forwardCurvesColumns = [
  { accessorKey: 'Code', header: 'Code', size: 100 },
  { accessorKey: 'TimeRef', header: 'TimeRef', size: 95 },
  { accessorKey: 'PeriodType', header: 'PeriodType', size: 120 },
  { accessorKey: 'Periods', header: '# Periods', size: 100 },
  { accessorKey: 'Title', header: 'Title', size: 550 },
  { accessorKey: 'Units', header: 'Units', size: 100 },
  { accessorKey: 'Currency', header: 'Currency', size: 100 },
  { accessorKey: 'Commodity', header: 'Commodity' },
  { accessorKey: 'TradingHub', header: 'Trading Hub' },
  { accessorKey: 'PricingBasis', header: 'Pricing Basis', size: 150 },
  { accessorKey: 'DeliveryBasis', header: 'Delivery Basis', size: 150 },
  { accessorKey: 'Frequency', header: 'Frequency', size: 120 },
  { accessorKey: 'IndexModule', header: 'Index Module', size: 200 },
  { accessorKey: 'StartDate', header: 'StartDate', size: 120 },
  { accessorKey: 'EndDate', header: 'EndDate', size: 120 },
  { accessorKey: 'FactsheetVersion', header: 'Factsheet Version', size: 170 },
  { accessorKey: 'TimeZone', header: 'Time Zone', size: 140 },
  { accessorKey: 'HolidayCalendar', header: 'Holiday Calendar', size: 220 },
  { accessorKey: 'Increment', header: 'Increment', size: 110 },
  { accessorKey: 'Index', header: 'Columns Available', size: 170 },
  { accessorKey: 'Factsheet', header: 'Factsheet Link', size: 135 }
];

export const defaultVisibilityState = {
  Factsheet: false,
  StartDate: false,
  EndDate: false,
  FactsheetVersion: false,
  TimeZone: false,
  HolidayCalendar: false,
  Increment: false,
  Index: false,
  TimeRef: true,
  PeriodType: true,
  Period: true,
  PricingBasis: false,
  DeliveryBasis: false,
  Frequency: false,
  IndexModule: false
};

export const displayedSettingsColumnsList = [
  {
    label: 'Default columns',
    columns: [
      {
        displayLabel: 'Units',
        accessorKey: 'Units'
      },
      {
        displayLabel: 'Currency',
        accessorKey: 'Currency'
      },
      {
        displayLabel: 'Commodity',
        accessorKey: 'Commodity'
      },
      {
        displayLabel: 'Trading Hub',
        accessorKey: 'TradingHub'
      }
    ]
  },
  {
    label: 'Additional Filters',
    columns: [
      {
        displayLabel: 'Pricing Basis',
        accessorKey: 'PricingBasis'
      },
      {
        displayLabel: 'Delivery Basis',
        accessorKey: 'DeliveryBasis'
      },
      {
        displayLabel: 'Frequency',
        accessorKey: 'Frequency'
      },
      {
        displayLabel: 'Index Module',
        accessorKey: 'IndexModule'
      }
    ]
  },
  {
    label: 'Additional columns',
    columns: [
      {
        displayLabel: 'Start Date',
        accessorKey: 'StartDate'
      },
      {
        displayLabel: 'End Date',
        accessorKey: 'EndDate'
      },
      {
        displayLabel: 'Factsheet Version',
        accessorKey: 'FactsheetVersion'
      },
      {
        displayLabel: 'Time Zone',
        accessorKey: 'TimeZone'
      },
      {
        displayLabel: 'Holiday Calendar',
        accessorKey: 'HolidayCalendar'
      },
      {
        displayLabel: 'Increment',
        accessorKey: 'Increment'
      },
      {
        displayLabel: 'Columns Available',
        accessorKey: 'Index'
      },
      {
        displayLabel: 'Factsheet Link',
        accessorKey: 'Factsheet'
      }
    ]
  }
];
