import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { ChartSeriesType } from 'pages/Insights/InsightsComponents/types';
import httpInstances from 'api/axiosInstance';
import useDateText, { DateTextVariants } from 'components/DateText/useDateText';
import useChartOptions from './useChartOptions';

type ChartDataType = {
  symbols: {
    key: {
      Code: string;
      Period: string;
      PeriodType: string;
    };
    metadata: {
      Alias: string;
      Title: string;
      Currency: string;
    };
    series: [];
  }[];
};

const chartLinesPalette = [
  '#00BFFF',
  '#FFD700',
  '#2E8B57',
  '#6363DF',
  '#F3733F',
  '#0F2886',
  '#E4597B',
  '#53F247',
  '#7FD4B6',
  '#F8AE12'
];

const useStockChart = (templateChartSeries: ChartSeriesType[], height?: number) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [chartData, setChartData] = useState<ChartDataType>();
  const [error, setError] = useState<boolean>(false);
  const [chartSeries, setChartSeries] = useState<any>();
  const { formatDateString } = useDateText();
  const { createOptions } = useChartOptions({ chartSeries, height });

  useEffect(() => {
    fetchChartData();
  }, []);

  useEffect(() => {
    if (chartData) setChartSeries(createSeries());
  }, [chartData]);

  const fetchChartData = async () => {
    try {
      const symbols = templateChartSeries.map(({ symbol }) => symbol);
      const { data } = await httpInstances.axiosDataInstance.post(`/go/index/graph/spot`, {
        symbols
      });
      setChartData(data);
    } catch (error) {
      if (error?.response) {
        setError(true);
      }
    } finally {
      setLoading(false);
    }
  };

  function createSeries() {
    return chartData?.symbols?.flatMap(
      ({ key, metadata, metadata: { Alias, Title }, series }, symbolIndex) => {
        const shortTitle = getShortTitle(templateChartSeries, key);

        return Object.keys(series ?? {}).map((field: string, fieldIndex) => {
          const combinedIndex = symbolIndex * Object.keys(series).length + fieldIndex;
          // @ts-expect-error ---
          const tsData = series[field].map(tsData => {
            return {
              x: tsData[0],
              y: Number(tsData[1]),
              custom: {
                periodAbs: tsData.length === 3 ? parsePeriodAbs(tsData[2]) : ''
              }
            };
          });

          return {
            showInNavigator: true,
            name: shortTitle || Alias || Title,
            data: tsData,
            // lastPrice: {
            //   color: '#0D1228',
            //   enabled: true,
            //   label: {
            //     shape:'box',
            //     borderRadius: 0,
            //     padding: 4,
            //     backgroundColor: chartLinesPalette[combinedIndex % chartLinesPalette.length],
            //     enabled: true,
            //     align: 'right',
            //     style: {
            //       fontSize:10,
            //       fontWeight: 400,
            //       color: '#070818',
            //       textAlign: 'bottom'
            //     }
            //   }
            // },
            marker: {
              symbol: 'circle'
            },
            metadata: {
              hideName: Boolean(shortTitle),
              field: field,
              ...key,
              ...metadata
            },
            color: chartLinesPalette[combinedIndex % chartLinesPalette.length]
          };
        });
      }
    );
  }
  function getShortTitle(
    templateChartSeries: ChartSeriesType[],
    key: { Code: string; Period: string; PeriodType: string }
  ): string | undefined {
    return templateChartSeries.find(templateSeries => {
      const templateSeriesSymbol: any = templateSeries.symbol;
      return JSON.stringify(key) === JSON.stringify(templateSeriesSymbol.key);
    })?.shortTitle;
  }

  function parsePeriodAbs(periodAbs: string): string {
    try {
      if (/^\d/.test(periodAbs)) {
        if (periodAbs.length > 7) {
          return formatDateString(periodAbs, DateTextVariants.FULL);
        }
        return formatDateString(periodAbs, DateTextVariants.MONTH_YEAR);
      }
      return periodAbs;
    } catch (error) {
      console.log(error);
      return periodAbs;
    }
  }

  const options = chartSeries ? createOptions() : null;

  return {
    options,
    loading,
    error
  };
};

export default useStockChart;
