import { InputAdornment, TextField } from '@mui/material';
import { Search } from '@mui/icons-material';
import { ChangeEvent } from 'react';

const TableSearch = ({
  searchValue,
  handleChangeSearch
}: {
  searchValue: string;
  handleChangeSearch: (e: ChangeEvent<HTMLInputElement>) => void;
}) => (
  <TextField
    hiddenLabel
    value={searchValue}
    onChange={handleChangeSearch}
    sx={{
      minWidth: 200,
      '& .MuiFilledInput-input': {
        p: 1.5
      },
      '& .MuiInputBase-root': {
        backgroundColor: 'secondary.main'
      }
    }}
    slotProps={{
      input: {
        startAdornment: (
          <InputAdornment
            sx={{ m: 0 }}
            position='start'
          >
            <Search color='info' />
          </InputAdornment>
        )
      }
    }}
    variant='filled'
    placeholder='Search'
  />
);

export default TableSearch;
