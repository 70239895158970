import { Box, CircularProgress, Paper, Skeleton, Typography } from '@mui/material';

const CatalogSkeleton = () => (
  <Box data-testid='catalog-skeleton'>
    <Typography sx={{ my: 5, mx: 2 }}>Loading your data, please wait.</Typography>
    <Paper
      sx={{
        width: '100%',
        height: '100vh',
        p: 2,
        pt: 8,
        display: 'flex',
        flexDirection: 'column',
        gap: 1
      }}
    >
      <Box sx={{ flex: 1, overflow: 'hidden' }}>
        <Box sx={{ height: '100%' }}>
          {[...Array(30)].map((_, index) => (
            <Skeleton
              key={index}
              width='100%'
              height='23px'
              sx={{ my: 2 }}
            />
          ))}
        </Box>
      </Box>
    </Paper>
  </Box>
);

export default CatalogSkeleton;
