import { ChangeEvent, Fragment } from 'react';
import { DisplaySettings, ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  ButtonBase,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography
} from '@mui/material';
import Popper from 'components/Popper';
import Checkbox from 'components/Checkbox';
import { displayedSettingsColumnsList } from 'pages/Catalog/constants';

const buttonBaseStyle = { color: 'primary.main', px: 0.2, textDecoration: 'underline' };

const DisplaySettingsPopper = ({
  showDiscontinued,
  setShowDiscontinued,
  expandedData,
  setExpandedData,
  table,
  isForwardCurves
}: {
  showDiscontinued: boolean;
  setShowDiscontinued: (newValue: boolean) => void;
  expandedData: boolean;
  isForwardCurves: boolean;
  setExpandedData: (newValue: boolean) => void;
  table: any;
}) => {
  const tanStackColumns = table.getAllColumns();

  const handleChangeExpandedData = (e: ChangeEvent<HTMLInputElement>) => {
    if (isForwardCurves) return;
    setExpandedData(e.target.value === '1');
    const columnIdsToChangeVisibility = ['TimeRef', 'PeriodType', 'Period'];
    const columns = tanStackColumns.filter(column =>
      columnIdsToChangeVisibility.includes(column.id)
    );

    columns.forEach(column => {
      column.toggleVisibility();
    });
  };

  return (
    <Popper
      buttonVariant='outlined'
      buttonContent={
        <>
          <DisplaySettings sx={{ mr: 1 }} />
          Display Settings
        </>
      }
    >
      <Box
        sx={{
          minWidth: 280,
          borderBottom: '1px solid',
          borderLeft: '1px solid',
          borderColor: 'primary.main',
          display: 'flex',
          flexDirection: 'column',
          fontSize: '14px',
          minHeight: 200
        }}
      >
        <Accordion disabled={isForwardCurves}>
          <AccordionSummary
            sx={{
              '&.Mui-expanded': {
                backgroundColor: 'secondary.main'
              }
            }}
            expandIcon={<ExpandMore />}
          >
            Expanded Spot Data Catalog
          </AccordionSummary>
          <AccordionDetails sx={{ backgroundColor: 'secondary.main' }}>
            <FormControl>
              <RadioGroup
                aria-labelledby='demo-radio-buttons-group-label'
                value={expandedData ? '1' : '0'}
                onChange={handleChangeExpandedData}
              >
                <FormControlLabel
                  value='1'
                  control={<Radio />}
                  label='Display all data for each GX code'
                />
                <Typography
                  color='info'
                  component='div'
                  sx={{ fontSize: '12px', fontStyle: 'italic', mb: 0.5 }}
                >
                  Shows TimeRef, PeriodType, Period columns
                </Typography>
                <FormControlLabel
                  value='0'
                  control={<Radio />}
                  label='Display one data series per GX code'
                />
              </RadioGroup>
            </FormControl>
          </AccordionDetails>
        </Accordion>
        <FormControlLabel
          sx={{ m: 0, py: 1, px: 2 }}
          control={
            <Checkbox
              checked={showDiscontinued}
              onChange={() => setShowDiscontinued(!showDiscontinued)}
            />
          }
          label='Show discontinued series'
        />
        <Accordion>
          <AccordionSummary
            sx={{
              '&.Mui-expanded': {
                backgroundColor: 'secondary.main'
              }
            }}
            expandIcon={<ExpandMore />}
          >
            Additional Metadata columns
          </AccordionSummary>
          <AccordionDetails
            sx={{
              backgroundColor: 'secondary.main',
              pb: 0
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box>
                {displayedSettingsColumnsList.map(({ label, columns }) => (
                  <Fragment key={label}>
                    <Typography
                      color='info'
                      component='div'
                      sx={{ fontSize: '10px', textTransform: 'uppercase' }}
                    >
                      {label}
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'column', pb: 1 }}>
                      {columns.map(({ displayLabel, accessorKey }) => {
                        const tanStackColumn = tanStackColumns.find(
                          ({ id }: { id: string }) => id === accessorKey
                        );
                        return (
                          <FormControlLabel
                            key={accessorKey}
                            sx={{ m: 0, py: 0.1 }}
                            control={
                              <Checkbox
                                checked={tanStackColumn?.getIsVisible()}
                                onChange={tanStackColumn?.getToggleVisibilityHandler()}
                              />
                            }
                            label={displayLabel}
                          />
                        );
                      })}
                    </Box>
                  </Fragment>
                ))}
              </Box>
              <Box>
                <ButtonBase sx={buttonBaseStyle}>Select all</ButtonBase>
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Popper>
  );
};

export default DisplaySettingsPopper;
