import { Theme } from '@mui/material';
import { ChartSeriesType } from '../../types';
const createRangeSelector = (theme: Theme, chartSeries: ChartSeriesType) => {
  return {
    labelStyle: {
      display: 'none'
    },
    x: -30,
    buttonSpacing: 8,
    buttonTheme: {
      height: 22,
      width: 40,
      r: 0,
      fill: '#121836',
      style: {
        color: '#FFF'
      },
      states: {
        disabled: {
          style: {
            color: 'rgba(255, 255, 255, 0.3)'
          },
          fill: 'rgba(255, 255, 255, 0.12)'
        },
        hover: {
          style: {
            color: theme.palette.text.secondary
          }
        },
        select: {
          fill: theme.palette.primary.main,
          style: {
            color: '#FFF'
          }
        }
      }
    },
    buttons: [
      {
        type: 'month',
        count: 3,
        text: '3m'
      },
      {
        type: 'year',
        count: 1,
        text: '1y'
      },
      {
        type: 'year',
        count: 5,
        text: '5y'
      },
      {
        type: 'all',
        count: 1,
        text: 'Max'
      }
    ],
    selected: determineTimerangeButtonIndex(chartSeries),
    inputEnabled: false
  };
};

function determineTimerangeButtonIndex(chartSeries: any): number {
  function isOneYearBefore(timestamp: string) {
    const currentDate = new Date();
    const oneYearAgo = new Date();

    oneYearAgo.setFullYear(currentDate.getFullYear() - 1);

    const timestampDate = new Date(timestamp);

    return timestampDate < oneYearAgo;
  }

  const anySeriesLongerThanYear = chartSeries?.find((series: any) =>
    isOneYearBefore(series.data[0].x)
  );
  return anySeriesLongerThanYear ? 1 : 3;
}

export default createRangeSelector;
