import { ReactElement } from 'react';
import { HubspotTrackingLister } from './hubspotTrackingListener';
import { GoogleTrackingLister } from './googleTrackingListener';

export function TrackingLister({ children }: { children: ReactElement }) {
  return (
    <HubspotTrackingLister>
      <GoogleTrackingLister>{children}</GoogleTrackingLister>
    </HubspotTrackingLister>
  );
}
